









































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  IfEmpty,
  hideContent,
} from "@/components/template-transfer-pc/template-transfer/common";
import MainHeader from "../../../main/components/main-header/main-header.vue";
import NavLeft from "../../../main/components/nav-left/nav-left.vue";
import TemplateTransfer from "@/components/template-transfer-pc/template-transfer/template-transfer.vue";
import {
  TemplateGroupDetail,
  TemplateDetail,
  saveContent,
  zhenciDetail,
  contentDetail,
} from "@/request/doctor";
@Component({
  components: {
    MainHeader,
    NavLeft,
    TemplateTransfer,
  },
})
export default class Name extends Vue {
  private redIndex: any = -1; // 需要标红提示未填的index
  private zhenci_id: any;
  private H: any = 0;
  private time: any;
  private type: any = "";
  private disabledDate: any = this.beginDate();
  private navData: any = {
    templates: {},
  };
  private navs: any = this.configuration.liangbiaoTab;
  private cNav: any = this.configuration.liangbiaoTab[0].label;
  private cPart: any = {};
  private templates: any = [];
  private content: any = {};
  private bitianMsg: any = [];
  private beginDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        return time.getTime() > Date.now();
      },
    };
  }
  private get configuration() {
    this.$store.state.configuration.liangbiaoTab.forEach((ele: any) => {
      this.navData.templates[ele.label] = [];
    });
    return this.$store.state.configuration;
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private get user() {
    return this.$store.state.user;
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private changeTab(item: any) {
    this.cPart = item;
    this.$store.commit("updateIfCompose", false);
    this.redIndex = -1;
    this.getTemplates();
  }
  private getNavData() {
    const params = {
      zhenci_id: this.zhenci_id,
    };
    zhenciDetail(this, params).then((res: any) => {
      this.navData = res;
      if (this.cPart.template_id && !this.cPart.content_id) {
        // 存在表示是新建保存是调，需要置入content_id
        res.templates[this.cNav].forEach((ele: any) => {
          if (ele.template_id === this.cPart.template_id) {
            this.cPart = ele;
          }
        });
        // 刷新量表数据
        this.getTemplates();
      }
    });
  }
  private getTemplates() {
    if (this.cPart.content_id) {
      const params = {
        content_id: this.cPart.content_id,
      };
      contentDetail(this, params).then((res: any) => {
        this.content = res;
        this.templates = res.data;
      });
    } else {
      const params = {
        template_id: this.cPart.template_id,
      };
      TemplateDetail(this, params).then((res: any) => {
        this.content = res;
        this.templates = res["模板"];
      });
    }
  }
  private updateTemplate(e: any) {
    this.templates[e.index] = e.data;
    this.$forceUpdate();
    //计算BMI，量表顺序只能是身高，体重，BMI
    if (e.data.title === "身高") {
      if (this.templates[e.index + 2].title !== "BMI") {
        return;
      }
      const shengao = this.templates[e.index].value / 100;
      const tizhong = this.templates[e.index + 1].value;
      this.templates[e.index + 2].value = (
        tizhong /
        (shengao * shengao)
      ).toFixed(2);
    } else if (e.data.title === "体重") {
      if (this.templates[e.index + 1].title !== "BMI") {
        return;
      }
      const shengao = this.templates[e.index - 1].value / 100;
      const tizhong = this.templates[e.index].value;
      this.templates[e.index + 1].value = (
        tizhong /
        (shengao * shengao)
      ).toFixed(2);
    }
    this.$forceUpdate();
  }
  private createTipMes() {
    if (!this.templates && this.templates.length === 0) {
      return;
    }
    this.bitianMsg = [];
    this.templates.forEach((ele: any, i: any) => {
      if (ele.is_required) {
        this.bitianMsg.push({
          required: false,
          tipMsg: ele.title + "为必填题！",
        });
      } else {
        this.bitianMsg.push({
          required: false,
          tipMsg: "",
        });
      }
    });
  }
  private save() {
    // 判断必填项是否填完
    for (let i = 0; i < this.templates.length; i++) {
      const weitian = IfEmpty(this.templates[i]);
      if (weitian) {
        // 弹提示哪个未填,并用红框框上
        this.$message.warning(this.templates[i].title + "为必填项！");
        this.redIndex = i;
        // 滚动到没填的题目
        const parent = (document as any).getElementById("menzhen-edit-right");
        const child = (document as any).getElementById("liangbiao" + i);
        parent.scrollTop = child.offsetTop - parent.offsetTop;
        return;
      }
    }
    let data: any = this.content;
    data.data = this.templates;
    data.zhenci_id = this.zhenci_id;
    data.patient_id = this.patient.patient_id;
    if (!data["采集时间"]) {
      data["采集时间"] = this.patient.create_time;
    }
    saveContent(this, data).then((res: any) => {
      this.$message.success("保存成功")!;
      this.redIndex = -1;
      this.$store.commit("updateIfCompose", false);
      this.getNavData();
      this.getTemplates();
    });
  }
  /**
   * @description 隐藏或者显示某些相关题目
   */
  private changeHide(e: any) {
    const data = this.templates;
    hideContent(e, data);
  }
  /**
   * @description 清除隐藏题目的value
   */
  private clearVal(item: any) {
    if (item.type === "计分单选" || item.type === "sf36计分单选") {
      item.value = {};
    } else if (item.type === "单选补充文字" || item.type === "多选补充文字") {
      item.options.forEach((ele: any) => {
        ele.value = false;
        if (ele.content) {
          ele.content = "";
        }
        if (ele.extra) {
          ele.extra.forEach((ext: any) => {
            if (ext.type === "多选") {
              ext.value = [];
            } else {
              ext.value = "";
            }
          });
        }
      });
    } else if (item.type === "多选") {
      item.value = [];
    } else {
      item.value = "";
    }
  }
  private edit() {
    this.$store.commit("updateIfCompose", true);
  }
  private mounted() {
    this.H = document.body.clientHeight - 78 - 40 - 14;
    if (this.$route.query.zhenci_id) {
      this.zhenci_id = this.$route.query.zhenci_id;
      if (this.$route.query.type) {
        this.$store.commit("updateIfCompose", false);
      } else {
        this.$store.commit("updateIfCompose", true);
      }
      this.getNavData();
    }
    if (this.type === "add") {
      this.$store.commit("updateIfCompose", false);
    }
  }
}
