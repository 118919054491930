var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-project"},[_c('div',{staticClass:"top-header"},[_c('main-header')],1),_c('div',{staticClass:"main-wrap"},[_c('div',{staticClass:"main-left"},[_c('nav-left',{attrs:{"cState":"患者档案"}})],1),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"menzhen-edit"},[_c('div',{staticClass:"controlWrap"},[_c('div',{staticClass:"control-text"},[(_vm.type === 'add')?_c('span',[_vm._v("新增")]):_vm._e(),_vm._v("随访记录 ")]),_c('div',{staticClass:"control-content"},[_c('div',[_vm._v(_vm._s(_vm.patient["姓名"]))]),_c('div',[_vm._v(_vm._s(_vm.patient["性别"]))]),_c('div',[_vm._v(_vm._s(_vm.patient["年龄"])+"岁")])]),_c('div',{staticClass:"control-btn"},[(!_vm.ifCompose && _vm.cPart.template_id)?_c('div',{staticClass:"primary defaultBtn",on:{"click":_vm.edit}},[_vm._v(" 编辑 ")]):_vm._e(),(_vm.ifCompose && _vm.cPart.template_id)?_c('div',{staticClass:"primary defaultBtn",on:{"click":_vm.save}},[_vm._v(" 保存 ")]):_vm._e(),_c('div',{staticClass:"return",on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("返回")])])]),_c('div',{staticClass:"menzhen-edit-content"},[_c('div',{staticClass:"menzhen-edit-left",style:({ height: _vm.H + 'px' })},[_c('div',{staticClass:"tab"},_vm._l((_vm.navs),function(item,index){return _c('div',{key:index,on:{"click":function($event){_vm.cNav = item.label}}},[_c('div',{class:{ active: _vm.cNav === item.label }},[_vm._v(" "+_vm._s(item.label)+" ")])])}),0),_c('div',{staticClass:"singel"},_vm._l((_vm.navData.templates[_vm.cNav]),function(item,index){return _c('div',{key:index,class:{
                  defaultBtn: _vm.cPart.template_id === item['template_id'],
                },on:{"click":function($event){return _vm.changeTab(item)}}},[_vm._v(" "+_vm._s(item["名称"])+" ")])}),0),(_vm.navData.templates[_vm.cNav].length === 0)?_c('div',{staticClass:"noMsg"},[_vm._v(" 该分类暂无量表！ ")]):_vm._e()]),(_vm.content['名称'])?_c('div',{staticClass:"menzhen-edit-right",style:({ height: _vm.H + 'px' }),attrs:{"id":"menzhen-edit-right"}},[_c('div',{staticClass:"menzhen-edit-right-top"},[_c('div',{staticClass:"timeBox"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.content["名称"]))]),_c('div',{staticClass:"time"},[_c('div',[_vm._v("采集时间:")]),_c('el-date-picker',{attrs:{"disabled":!_vm.ifCompose,"size":"small","type":"datetime","format":"yyyy-MM-dd hh:mm:ss","value-format":"yyyy-MM-dd hh:mm:ss","placeholder":"采集时间","picker-options":_vm.disabledDate},model:{value:(_vm.content['采集时间']),callback:function ($$v) {_vm.$set(_vm.content, '采集时间', $$v)},expression:"content['采集时间']"}})],1)])]),(
                _vm.content.content_id &&
                _vm.cNav === '健康测评' &&
                _vm.content['need_score']
              )?_c('div',{staticClass:"menzhen-edit-result"},[_c('div',{staticClass:"result"},[(_vm.content.score)?_c('div',[_vm._v("得分："+_vm._s(_vm.content["score"]))]):_vm._e(),(_vm.content.result)?_c('div',[_c('i',[_vm._v("判定结果：")]),_c('pre',{domProps:{"innerHTML":_vm._s(_vm.content.result)}},[_vm._v(_vm._s(_vm.content.result))])]):_vm._e()]),_c('div',{staticClass:"menzhen-edit-result-title"},[_vm._v("详情")])]):_vm._e(),_c('div',{staticClass:"menzhen-edit-right-liangbiao"},_vm._l((_vm.templates),function(t,tIndex){return _c('div',{key:tIndex,class:{
                  botBorder: !t.hide,
                  redBorder: _vm.redIndex === tIndex,
                },attrs:{"id":'liangbiao' + tIndex}},[(!t.hide)?_c('TemplateTransfer',{attrs:{"index":tIndex,"content":t},on:{"update:content":_vm.updateTemplate,"refresh":_vm.getTemplates,"changeHide":_vm.changeHide}}):_vm._e()],1)}),0)]):_vm._e(),(!_vm.content['名称'])?_c('div',[_vm._v("请在左边选择一张量表")]):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }